<template>
  <button-full-rectangonal @click.native="validation ? addToCart(product) : emitClick()" :disabled="isProductDisabled" data-testid="addToCart">
    <template v-if="showPrice">
      {{ showPriceText }} {{ product.price | price(storeView) }}
    </template>
    <template v-else>
      {{ $t(text) }}
    </template>
  </button-full-rectangonal>
</template>

<script>
import { formatProductMessages } from '@vue-storefront/core/filters/product-messages'
import { notifications } from '@vue-storefront/core/modules/cart/helpers'
import focusClean from 'theme/components/theme/directives/focusClean'
import { mapGetters } from 'vuex'
import ButtonFullRectangonal from 'theme/components/theme/ButtonFullRectangonal.vue'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  directives: { focusClean },
  props: {
    product: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showPrice: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Date,
      default: () => new Date()
    },
    isShowIcon: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'Add to cart'
    },
    showPriceText: {
      type: String,
      default: ''
    }
  },
  components: {
    ButtonFullRectangonal
  },
  methods: {
    emitClick () {
      this.$emit('click-add-to-cart')
    },
    onAfterRemovedVariant () {
      this.$forceUpdate()
    },
    async addToCart (product) {
      try {
        const diffLog = await this.$store.dispatch('cart/addItem', { productToAdd: product })
        diffLog.clientNotifications.forEach(notificationData => {
          this.notifyUser(notificationData)
        })
      } catch (message) {
        this.notifyUser(notifications.createNotification({ type: 'error', message }))
      }
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData, { root: true })
    }
  },
  computed: {
    ...mapGetters({
      isAddingToCart: 'cart/getIsAdding'
    }),
    isProductDisabled () {
      return this.disabled || formatProductMessages(this.product.errors) !== '' || this.isAddingToCart
    },
    storeView () {
      return currentStoreView()
    },
  },
  beforeMount () {
    this.$bus.$on('product-after-removevariant', this.onAfterRemovedVariant)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-removevariant')
  }
}
</script>
