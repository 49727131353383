<template>
  <div class="wishlist cl-accent bg-cl-white">
    <div class="whislist-header row middle-xs between-xs bg-cl-white">
      <h2 v-if="productsInWishlist.length" class="h3 name-wishlist m0 cl-black">
        {{ $t('Wishlist') }}
      </h2>
      <h4 v-if="!productsInWishlist.length" class="h3 name-wishlist m0 cl-black">
        {{ $t('Your wishlist is empty.') }}
      </h4>
      <i class="icon-close cl-black fs16 pointer" @click="closeWishlist" />
    </div>
    <div class="flex clear-wishlist">
      <p v-if="productsInWishlist.length" @click="clearWishlist" class="weight-400 m0">
        {{ $t('Сlear Wishlist') }}
      </p>
    </div>
    <!-- <div v-if="productsInWishlist.length" @click="clearWishlist" class="visible-xs pl10 pt15">
      <p class="weight-600 h6 m0 cl-main">
        {{ $t('Wyczyść listę życzeń') }}
      </p>
    </div> -->
    <p v-if="!productsInWishlist.length" class="ml30 no-products h4 pl20 mt50 cl-black">
      {{ $t("Click the heart icons on items you love.") }}
    </p>
    <!-- <ul v-show="$device.isMobile" class="products">
      <product v-for="wishlistProduct in productsInWishlist" :key="wishlistProduct.id" :product="wishlistProduct" />
    </ul> -->
    <product-listing class="m0 col-xs-12 mt20" columns="wishlist" :filter-toogle="true" :products="productsInWishlist" />
    <div class="mb50 env-padding-bottom">
      <div class="row m0 py15 center-xs start-md">
        <div class="pl30 align-left col-xs-12 col-sm-8 p0">
          <h3 class="m0 pl20 mb5 viewed-products cl-black">
            {{ $t('My Recently viewed') }}
          </h3>
        </div>
      </div>
      <div class="row m0">
        <div class="col-xs-12 p0" v-if="sliceRecentlyView && sliceRecentlyView.length > 0">
          <product-listing :recently-view="true" class="m0 product-listing" columns="wishlist" :filter-toogle="true" :products="sliceRecentlyView" />
        </div>
        <div class="col-xs-12 no-products h4 p0 ml50 cl-black" v-else>
          <p>{{ $t('No products yet') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Wishlist from '@vue-storefront/core/compatibility/components/blocks/Wishlist/Wishlist'
import Product from 'theme/components/core/blocks/Wishlist/Product'
import ProductListing from 'theme/components/core/ProductListing.vue'
import RecentlyViewed from '@vue-storefront/core/modules/recently-viewed/components/RecentlyViewed'

export default {
  props: {
    product: {
      type: Object,
      required: false,
      default: () => { }
    }
  },
  components: {
    Product,
    ProductListing
  },
  computed: {
    sliceRecentlyView () {
      if (this.items && this.items.length > 0) {
        let temp = Array.from(new Set(this.items.slice(0, 9)))
        return temp
      } else {
        return []
      }
    }
  },
  methods: {
    clearWishlist () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Are you sure you would like to remove all the items from the wishlist?'),
        action1: { label: this.$t('OK'),
          action: () => {
            this.$store.dispatch('wishlist/clear')
          }
        },
        action2: { label: this.$t('Cancel'), action: 'close' },
        hasNoTimeout: true
      })
    }
  },
  mixins: [Wishlist, RecentlyViewed]
}
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
h3 {
  @media (max-width: 767px) {
    font-size: 20px;
  }
}
.icon-close {
  @media (max-width: 767px) {
  font-size: 35px;
  }
}
.whislist-header {
  padding: 0 30px 0px 50px;
  padding-top: 25px;
  @media (max-width: 767px) {
      padding-top: 10px;
      padding: 0 15px 0px 25px;
  //   border-bottom-left-radius: 9px;
  //   border-bottom-right-radius: 9px;
  //   box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  //   padding: 0 15px;
  //   margin: 0;
  }
}
.viewed-products {
  font-size: 32px;
  font-weight: 500;
  color: #333C39;
  text-shadow: 0 0 black;
  @media (max-width: 767px) {
    font-size: 22px;
    color: #000000;
    padding-left: 16px;
  }
}
.pl30 {
  @media (max-width: 767px) {
    padding-left: 0;
  }
}
.no-products {
  @media (max-width: 767px) {
    margin: 0;
  }
}
.product-listing {
    border-top: 1px solid;
    margin-left: 43px;
    padding: 0;
    margin-right: 40px;
    margin-top: 10px;
    padding-top: 10px;
  @media (max-width: 767px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
}
.clear-wishlist {
  padding-left: 43px;
  p {
    text-decoration: underline;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    color:black;
  }
  @media (max-width: 767px) {
    padding-left: 16px;
  }
}
.name-wishlist {
  font-weight: 500;
  font-size: 32px;
  text-shadow: 0 0 #b3b3b3;
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 23px;
  }
}
.empty-wishlist {
  padding: 15px 0;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.no-products {
  @media (max-width: 767px) {
    margin-left: 0px;
    padding-left: 20px;
  }
}
.products {
  padding-left: 10px;
}
</style>