<template>
  <li class="row m0 pr15 py15 list-item cl-black">
    <div class="blend bg-cl-secondary col-xs-5 p0" @click="closeWishlist">
      <router-link :to="productLink">
        <product-image :image="image" />
      </router-link>
    </div>
    <div class="col-xs-7 pl10 pr0 relative">
      <p class="m0 cl-black weight-900">
        {{ product.name | htmlDecode }}
      </p>
      <div class="h6 pt5 sku">
        SKU: {{ product.sku }}
      </div>
      <div class="py10 flex between-xs">
        <div>
          <span class="price-special block cl-burnt-sienna" v-if="product.special_price">{{ product.price_incl_tax | price(storeView) }}</span>
          <span class="price-original" v-if="product.special_price">{{ product.original_price_incl_tax | price(storeView) }}</span>

          <span v-if="!product.special_price">
            Cena: {{ product.price_incl_tax | price(storeView) }}
          </span>
        </div>
        <div>
          <span @click="removeProductFromWhishList(product)"><remove-button class="cl-black" /></span>
        </div>
      </div>
      <div @click="closeWishlist" class="check-container mx0 mt10">
        <router-link :to="productLink">
          <p class="check-btn m0 cl-white bg-cl-main">
            {{ $t('Check') }}
          </p>
        </router-link>
      </div>
      <div class="add-to-cart-container center-xs" v-if="showAddToCart">
        <add-to-cart
          v-if="product.type_id === 'simple'"
          :product="product"
          class="wishlist-add-to-cart m0 cl-white bg-cl-main"
        />
        <!-- <router-link
          v-else
          :to="productLink"
          class="wishlist-add-to-cart no-outline button-full block brdr-none w-100 px10 py20 bg-cl-mine-shaft :bg-cl-th-secondary ripple weight-400 h4 cl-white sans-serif fs-medium col-xs-12 col-sm-4 col-md-6"
        >
          {{ $t('Configure') }}
        </router-link> -->
      </div>
    </div>
  </li>
</template>

<script>
import config from 'config'
import Product from '@vue-storefront/core/compatibility/components/blocks/Wishlist/Product'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import ProductImage from 'theme/components/core/ProductImage'
import RemoveButton from './RemoveButton'
import i18n from '@vue-storefront/i18n'
import { htmlDecode } from '@vue-storefront/core/lib/store/filters'
import AddToCart from 'theme/components/core/AddToCart'

export default {
  components: {
    RemoveButton,
    ProductImage,
    AddToCart
  },
  mixins: [Product],
  props: {
    showAddToCart: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    productLink () {
      return formatProductLink(this.product, currentStoreView().storeCode)
    },
    image () {
      return {
        loading: this.thumbnail,
        src: this.thumbnail
      }
    },
    storeView () {
      return currentStoreView()
    }
  },
  methods: {
    removeProductFromWhishList (product) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('Product {productName} has been removed from wishlist!', { productName: htmlDecode(product.name) }),
        action1: { label: i18n.t('OK') }
      }, { root: true })
      this.removeFromWishlist(product)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$matterhorn: color(matterhorn);
.check-btn {
  padding: 3px 40px;
  border-radius: 15px;
}
@media (max-width: 767px) {
  .list-item {
    box-shadow: 0 4px 2px -4px $matterhorn;
  }
}
.blend {
  margin: auto;
  border-radius: 9px;
  overflow: hidden;
}
.col-xs {
  flex-direction: column;
}
input {
  width: 30px;
}
.price-original {
  text-decoration: line-through;
  color: #828282;
  font-size: .95rem;
}
@media (max-width: 787px) {
  .check-container {
    text-align: center;
    width: 100%;
  }
  .add-to-cart-container {
    width: 100%;
    .wishlist-add-to-cart {
      width: 100%;
      white-space: nowrap;
    }
  }
}

.wishlist-add-to-cart {
  padding: 3px 10px;
  border-radius: 15px;
  margin: 10px 0;
//  min-width: 100px;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}
.price-original {
  text-decoration: line-through;
  color: #828282;
  font-size: .95rem;
}
.price-original {
  text-decoration: line-through;
  color: #828282;
  font-size: .95rem;
}
</style>
